import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";
import AuthHeader from "../../components/AuthHeader";
import AuthFooter from "../../components/AuthFooter";

const LoginPage = lazy(() => import("./LoginPage"));
const SignInPage = lazy(() => import("./SignInPage"));
const EsqueceuSenhaPage = lazy(() => import("./EsqueceuSenhaPage"));
const RecuperarSenhaPage = lazy(() => import("./RecuperarSenhaPage"));
const CodigoSenhaPage = lazy(() => import("./CodigoSenhaPage"));

export default function AuthPage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <div className="container">
                <AuthHeader />

                <Switch>
                    <Redirect exact from="/" to="/login" />

                    <Route path="/login" component={LoginPage} />
                    <Route path="/sign-in" component={SignInPage} />
                    <Route path="/esqueceu-senha" component={EsqueceuSenhaPage} />
                    <Route path="/codigo-senha" component={CodigoSenhaPage} />
                    <Route path="/recuperar-senha" component={RecuperarSenhaPage} />

                    <Redirect to="/" />
                </Switch>

                <AuthFooter />
            </div>
        </Suspense>
    );
}
