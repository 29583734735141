import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as banks from "./ducks/banks.duck";
import * as parameters from "./ducks/parameters.duck";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    banks: banks.reducer,
    parameters: parameters.reducer,
});

export function* rootSaga() {
    yield all([auth.saga(), banks.saga(), parameters.saga()]);
}
