import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../components/SplashScreen";
import Header from "../../components/Header";
import AuthFooter from "../../components/AuthFooter";
import { getParametros } from "../../crud/parametro.crud";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { actions } from "../../store/ducks/parameters.duck";

const DashboardPage = lazy(() => import("./dashboard/DashboardPage"));
const AntecipacaoPage = lazy(() => import("./antecipacao/AntecipacaoPage"));
const AntecipacaoView = lazy(() => import("./antecipacao/AntecipacaoView"));
const AntecipacaoNew = lazy(() => import("./antecipacao/AntecipacaoNew"));
const GerenciarContaPage = lazy(() => import("./gerenciar-conta/GerenciarContaPage"));

export default function HomePage() {

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const throwError = () => Swal.fire("Ops!", "Ocorreu algum erro ao carregar informações. Por favor entre em contato com o suporte.", "error");

    useEffect(() => {
        getParametros()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(actions.setParameters({
                        taxa_administracao: response.data.taxa_administracao,
                        taxa_operacao: response.data.taxa_operacao,
                        valor_minimo_antecipacao: response.data.valor_minimo_antecipacao,
                    }))
                    setLoading(false);
                } else {
                    throwError();
                }
            })
            .catch(() => {
                throwError();
            });
    }, [])


    if (loading) {
        return (<LayoutSplashScreen visible />);
    }

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <div className="container">
                <Header />

                <Switch>
                    <Route exact path="/inicio" component={DashboardPage} />

                    <Route exact path="/gerenciar-conta" component={GerenciarContaPage} />

                    <Route exact path="/antecipacao" component={AntecipacaoPage} />
                    <Route path="/antecipacao/view/:id" component={AntecipacaoView} />
                    <Route path="/antecipacao/new" component={AntecipacaoNew} />

                    <Route path="/*" render={() => (<Redirect to="/inicio" />)} />
                </Switch>

                <AuthFooter />
            </div>
        </Suspense>
    );

}
