import React from "react";
import styles from "../styles/AuthFooter.module.css";
import moment from "moment";
import { Link } from "react-router-dom";

export default function AuthFooter() {
    return (
        <footer className={`${styles.footer}`}>
            <p className="tt-14 color-gray-600 mb-0 ml-5">
                <a
                    className="tt-14 color-gray-600"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://linkash.com.br/">
                    Linkash
                </a> © {moment().format("YYYY")}
            </p>
            <div className="mr-5" style={{ gap: 30, display: 'flex' }} >
                <Link className="tt-14 color-gray-600" to="/">
                    Termos de uso
                </Link>
                <Link className="tt-14 color-gray-600" to="/">
                    Suporte
                </Link>
            </div>
        </footer>
    );
}
