import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    SET_BANKS: "SET_BANKS",
};

const initialAuthState = {
    banks: null,
};

export const reducer = persistReducer({ storage, key: "app-banks", whitelist: ["banks"] }, (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.SET_BANKS: {
            return {
                banks: action.payload,
            };
        }

        default:
            return state;
    }
});

export const actions = {
    setBanks: (payload) => ({ type: actionTypes.SET_BANKS, payload }),
};

export function* saga() { }
