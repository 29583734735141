import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiEditLine, RiLogoutBoxLine, RiHome2Line } from "react-icons/ri";
import { getFirstName, getIniciais } from "../utils/Utils";
import styles from "../styles/Header.module.css";

export default function Header() {
    const menuRef = useRef(null);

    const [openMenu, setOpenMenu] = useState(false);
    const [animation, setAnimation] = useState('out');

    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const toggleMenu = (to) => {
        if (to) {
            setAnimation('in');

            setOpenMenu(true);
        } else {
            setAnimation('out');

            const timer = setTimeout(() => {
                setOpenMenu(false);
            }, 300);

            return () => clearTimeout(timer);
        }
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            toggleMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className={styles.headerContainer}>
            <div className={styles.containerLeft} style={{ cursor: 'pointer' }} onClick={() => history.push("/inicio")}>
                <img src="/images/logo-linkash-branco.svg" className={styles.logo} alt="Logo" />
            </div>

            <div className={styles.containerRight} ref={menuRef}>
                <div onClick={() => toggleMenu(!openMenu)} style={{ display: 'flex', alignItems: "center", gap: 15, cursor: "pointer" }}>
                    <div className={styles.avatar} style={user?.foto ? { backgroundImage: `url(${user?.foto?.url})`, backgroundSize: "cover", backgroundPosition: "center", cursor: "default" } : null}>
                        {!user?.foto && <p>{getIniciais(user?.usr_responsavel_nome)}</p>}
                    </div>
                    <p className="tt-18 color-white m-0">{getFirstName(user?.usr_responsavel_nome)}</p>
                </div>


                <div className={`${styles.menu} ${animation === 'out' ? styles.fadeOut : styles.fadeIn}`} style={{ display: openMenu ? 'block' : 'none' }}>
                    <div className={styles.perfil}>
                        <div className={styles.avatar} style={user?.foto ? { backgroundImage: `url(${user?.foto?.url})`, backgroundSize: "cov er", backgroundPosition: "center" } : null}>
                            {!user?.foto && <p>{getIniciais(user?.usr_responsavel_nome)}</p>}
                        </div>

                        <div className={styles.nomes}>
                            <p className={`tt-18 medium color-gray-800 mb-0`}>{user?.usr_responsavel_nome}</p>

                            <p className={`tt-16 color-gray-600 mb-0`}>{user?.email}</p>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            setOpenMenu(false);
                            history.push("/gerenciar-conta");
                        }}
                        className={styles.opcao}
                    >
                        <div>
                            <RiEditLine size={20} color="#293037" />

                            <p className={`tt-18 medium color-gray-800 mb-0`}>Gerenciar conta</p>
                        </div>
                    </div>

                    <div
                        onClick={() => {
                            setOpenMenu(false);
                            history.push("/logout");
                        }}
                        className={styles.opcao}
                        style={{ border: "none", borderRadius: "0 0 8px 8px" }}
                    >
                        <div>
                            <RiLogoutBoxLine size={20} color="#293037" />

                            <p className={`tt-18 medium color-gray-800 mb-0`}>Sair</p>
                        </div>
                    </div>
                </div>


            </div>
        </header >
    );
}
