import axios from "axios";
import moment from "moment";

export const regexEmail = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;

export const createFormikValidation = (formMap, values) => {
    const errors = {};

    for (let [key, value] of formMap) {
        if (!values[key] && values[key] !== 0) {
            errors[key] = "O campo " + value + " é obrigatório.";
        } else if (key.includes("email") && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[key])) {
            errors[key] = "Você precisa informar um e-mail válido.";
        } else if ((key.includes("senha") || key.includes("password")) && values[key].length < 6) {
            errors[key] = "A senha deve ter pelo menos 6 caracteres.";
        } else if (key.includes("cep") && values[key].length < 5) {
            errors[key] = "Utilize no mínimo 8 caracteres.";
        } else if (key.includes("cpf") && key.includes("cnpj")) {
            if (values[key].length > 14 && !validaCnpj(values[key])) {
                errors[key] = "O CNPJ informado é inválido.";
            } else if (values[key].length <= 14 && !validaCpf(values[key])) {
                errors[key] = "O CPF informado é inválido.";
            }
        } else if (key.includes("cpf") && !key.includes("cnpj") && !validaCpf(values[key])) {
            errors[key] = "O CPF informado é inválido.";
        } else if (key.includes("cnpj") && !key.includes("cpf") && !validaCnpj(values[key])) {
            errors[key] = "O CNPJ informado é inválido.";
        } else if (key.includes("telefone") && values[key].length < 14) {
            errors[key] = "Utilize no mínimo 10 números.";
        }
    }

    return errors;
};

export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

export const formatBRL = (value) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    value = "R$ " + formatter.format(value);

    return value;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
    if (input.target.value.length > 8) {
        var cep = input.target.value.replace(/[^0-9]/, "");

        const instance = axios.create();

        instance
            .get(`https://viacep.com.br/ws/${cep}/json`, {})
            .then((res) => {
                if (res.data.erro) {
                    setFieldValue(nomeEndereco, "");
                    setFieldValue(nomeBairro, "");
                    setFieldValue(nomeCidade, "");
                    setFieldValue(nomeUf, "AC");
                } else {
                    setFieldValue(nomeEndereco, res.data.logradouro);
                    setFieldValue(nomeBairro, res.data.bairro);
                    setFieldValue(nomeCidade, res.data.localidade);
                    setFieldValue(nomeUf, res.data.uf);
                }
            })
            .catch((err) => { });
    }

    return formatCepInput(input);
};

export const formatCepInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");

    input.target.value = value;

    return input;
};

export const listaUfs = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];

export const formatDataInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");

    input.target.value = value;

    return input;
};

export const formatDataCartaoInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");

    input.target.value = value;

    return input;
};

export const formatCVV = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");

    input.target.value = value;

    return input;
};

export const formatNumeroCartaoInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{4})(\d)/, "$1 $2");
    value = value.replace(/(\d{4})(\d)/, "$1 $2");
    value = value.replace(/(\d{4})(\d)/, "$1 $2");

    input.target.value = value;

    return input;
};

export const enableLoading = (setLoading, setLoadingButtonStyle) => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
};

export const disableLoading = (setLoading, setLoadingButtonStyle) => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
};

export const formatData = (value) => {
    const data = value.split(" ")[0];

    return `${data.split("-")[2]}/${data.split("-")[1]}/${data.split("-")[0]}`;
};

export const formatCPFInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatTelefoneInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{0})(\d)/, "$1($2");
    value = value.replace(/(\d{2})(\d)/, "$1) $2");
    value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const lista_formato_imagens = ["jpg", "png", "jpeg", "image/jpg", "image/png", "image/jpeg"];
export const lista_formato_videos = ["mp4", "avi", "video/mp4", "video/avi"];

export const returnType = (name) => {
    const name_array = name.split(".");
    const type = name_array[name_array.length - 1];

    return type;
};

export const getCardFlag = (value) => {
    let card = value?.replace(/[^0-9]+/g, "");

    let cards = {
        visa: /^4[0-9]{12}(?:[0-9]{3})/,
        mastercard: /^5[1-5][0-9]{14}/,
        diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        amex: /^3[47][0-9]{13}/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
        elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}/,
        aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (let flag in cards) {
        if (cards[flag].test(card)) {
            return flag;
        }
    }

    return false;
};

/* eslint-disable */
export const validaCpf = (cpf) => {
    var add = 0;
    var rev = 0;

    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf == "") return false;

    if (cpf.length != 11) return false;

    // Verifica se todos os dígitos são iguais (ex: 111.111.111-11)
    if (/^(\d)\1+$/.test(cpf)) {
        return false;
    }

    for (var i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) {
        rev = 0;
    }
    if (rev != parseInt(cpf.charAt(9))) {
        return false;
    }

    add = 0;

    for (var j = 0; j < 10; j++) {
        add += parseInt(cpf.charAt(j)) * (11 - j);
    }

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) {
        rev = 0;
    }

    if (rev != parseInt(cpf.charAt(10))) {
        return false;
    }

    return true;
};
/* eslint-enable */

export const validaCnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length !== 14) {
        return false;
    }

    if (/^(\d)\1+$/.test(cnpj)) {
        return false;
    }

    let add = 0;
    let rev = 0;
    let pos = 5;

    for (let i = 0; i < 12; i++) {
        add += parseInt(cnpj.charAt(i)) * pos;
        pos = pos === 2 ? 9 : pos - 1;
    }

    rev = add % 11 < 2 ? 0 : 11 - (add % 11);

    if (rev !== parseInt(cnpj.charAt(12))) {
        return false;
    }

    add = 0;
    pos = 6;

    for (let i = 0; i < 13; i++) {
        add += parseInt(cnpj.charAt(i)) * pos;
        pos = pos === 2 ? 9 : pos - 1;
    }

    rev = add % 11 < 2 ? 0 : 11 - (add % 11);

    return rev === parseInt(cnpj.charAt(13));
};

export const getIniciais = (nome) => {
    if (!nome) {
        return "";
    }

    const array_name = nome?.split(" ");
    const name = array_name?.length > 1 ? `${array_name[0][0]}${array_name[1][0]}` : array_name[0][0];

    return name?.toUpperCase();
};

export const listaDiaSemana = [
    { label: "Segunda-feira", value: "segunda-feira" },
    { label: "Terça-feira", value: "terca-feira" },
    { label: "Quarta-feira", value: "quarta-feira" },
    { label: "Quinta-feira", value: "quinta-feira" },
    { label: "Sexta-feira", value: "sexta-feira" },
    { label: "Sabado", value: "sabado" },
    { label: "Domingo", value: "domingo" },
];

export const formatHora = (hora) => {
    hora = hora.replace(/\D/g, "");
    hora = hora.replace(/(\d{2})(\d)/, "$1:$2");

    return hora;
};

export const getValue = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
};

export const textoCorpoModal = (texto, dado) => {
    return texto.replace(/\{\{(.*?)\}\}/g, (_, p1) => {
        return getValue(dado, p1);
    });
};

export const formatacaoData = (data) => {
    if (moment(data).isSame(moment(), "day")) {
        return `Hoje, ${moment(data).format("HH:mm")}`;
    } else {
        return moment(data).format("DD/MM/YYYY[,] HH:mm");
    }
};

export const createHierarchy = (data) => {
    const map = {};

    data.forEach((item) => {
        const grupo_id = `grupo-${item.id}`;

        map[grupo_id] = { ...item, id: grupo_id, children: [], id_real: item.id, parent: [] };
    });

    const tree = [];

    data.forEach((item) => {
        const grupo_id = `grupo-${item.id}`;

        if (item.lgr_id_localizacao_grupo_pai === null) {
            tree.push(map[grupo_id]);
        } else {
            const parent_grupo_id = `grupo-${item.lgr_id_localizacao_grupo_pai}`;

            map[grupo_id] = { ...map[grupo_id], lgr_id_localizacao_grupo_pai: item?.lgr_id_localizacao_grupo_pai };

            map[grupo_id].parent = map[parent_grupo_id];

            map[parent_grupo_id].children.push(map[grupo_id]);
        }

        item.localizacoes.forEach((loc) => {
            const location_id = `localizacao-${loc.localizacao.id}`;

            map[grupo_id].children.push({
                ...loc.localizacao,
                id: location_id,
                id_real: loc.localizacao.id,
                children: [],
                lgr_id_localizacao_grupo_pai: loc?.lgl_id_localizacao_grupo,
                parent: map[grupo_id],
            });
        });
    });

    return tree;
};

// Função para formatar número com ponto para vírgula
export const formatNumberToPTBR = ({ value, minimumFractionDigits, maximumFractionDigits }) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits,
    });

    return formatter.format(value);
}

// Função para abreviar números grandes
export const formatLargeNumberToString = (num) => {
    if (num >= 1e9) {
        return formatNumberToPTBR({ value: (num / 1e9), maximumFractionDigits: 1 }) + 'b'; // Bilhão
    } else if (num >= 1e6) {
        return formatNumberToPTBR({ value: (num / 1e6), maximumFractionDigits: 1 }) + 'm'; // Milhão
    } else if (num >= 1e3) {
        return formatNumberToPTBR({ value: (num / 1e3), maximumFractionDigits: 1 }) + 'k'; // Milha
    } else {
        return num.toString(); // Menos que uma milha
    }
}

// Função para obter o nome do mês abreviado em português brasil e o ano abreviado
export const formatDateToPTBR = (date, withDay = false) => {
    // Formatter para o dia
    const dayFormatter = new Intl.DateTimeFormat('pt-BR', { day: '2-digit' });
    const day = dayFormatter.format(date).replace('.', '');

    // Opções para formatar o mês como abreviado
    const monthFormatter = new Intl.DateTimeFormat('pt-BR', { month: 'short' });
    const month = monthFormatter.format(date).replace('.', '');

    // Opções para formatar o ano como dois dígitos
    const yearFormatter = new Intl.DateTimeFormat('pt-BR', { year: '2-digit' });
    const year = yearFormatter.format(date);

    // Retorna o formato desejado
    if (withDay) {
        return `${month.charAt(0).toUpperCase() + month.slice(1)} ${day}, '${year}`;
    }

    return `${month.charAt(0).toUpperCase() + month.slice(1)} '${year}`;
}

// Função para obter o dia, nome do dia da semana e mês abreviado em português brasil
export const formatWeekDayMonthToPTBR = (date) => {
    const options = {
        weekday: 'short', // Abbreviated day name (e.g., "sáb.")
        day: 'numeric',   // Day of the month (e.g., "1")
        month: 'short',   // Abbreviated month name (e.g., "de out.")
    };
    const formatter = new Intl.DateTimeFormat('pt-BR', options);

    const formattedDate = formatter.format(date);

    return formattedDate.replace(/([A-Za-zá]+)\., (\d+) de (\w+)\./, (match, p1, p2, p3) => {
        let dayName = p1.charAt(0).toUpperCase() + p1.slice(1).toLowerCase();
        let monthName = p3.charAt(0).toLowerCase() + p3.slice(1).toLowerCase();
        return `${dayName}, ${p2} ${monthName}`;
    });
}

// Função para obter data e hora em português brasil
export const formatDatetimePTBR = (date) => {
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };

    const formatter = new Intl.DateTimeFormat('pt-BR', options);

    return formatter.format(date).replace(',', '');
}

export const formatCNPJInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const getFirstName = (nome_completo) => {
    if (!nome_completo) {
        return "";
    }

    return nome_completo?.split(" ")[0];
};

/**
 * Rounds value up to two decimal places
 * @param {number} value eg: 10.234
 * 
 * @return {number} eg: 10.24
 */
export const ceilCurrency = (value) => {

    return Math.ceil(value * 100) / 100;
}

export const validateFullName = (name) => {
    // Trim any leading/trailing spaces
    const trimmedName = name.trim();

    // Split the name by spaces (whitespace)
    const nameParts = trimmedName.split(/\s+/); // This splits by one or more spaces

    // Check if there are more than two parts and first & second are non-empty
    if (nameParts.length >= 2 && nameParts[0].length > 0 && nameParts[1].length > 0) {
        return true; // Valid first and last name
    }

    return false; // Invalid input
}

export const formatOnlyNumbersInput = (input) => {
    let value = input.target.value;

    // Remove tudo o que não for dígito (caracteres não numéricos)
    input.target.value = value.replace(/\D/g, "");

    return input;
};