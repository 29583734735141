import React from "react";
import styles from "../styles/AuthHeader.module.css";

export default function AuthHeader() {
    return (
        <header className={`${styles.header} containerRow`}>
            <img src="/images/logo-linkash-preto.svg" className={styles.logo} alt="Logo" />

            {/* <a className={styles.botaoSuporte} href="https://wa.me" target="_blank" rel="noopener noreferrer">
                <img src="/images/suporte.png" className={styles.logoSuporte} alt="Suporte" />

                <p className="tt-16 color-gray-800 medium mb-0">Suporte</p>
            </a> */}
        </header>
    );
}
