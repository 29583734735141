import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
    SET_PARAMETERS: "SET_PARAMETERS",
};

const initialAuthState = {
    taxa_administracao: 0,
    taxa_operacao: 0,
    valor_minimo_antecipacao: 0
};

export const reducer = persistReducer({ storage, key: "app-parameters", whitelist: ["parameters"] }, (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.SET_PARAMETERS: {
            return {
                parameters: action.payload,
            };
        }

        default:
            return state;
    }
});

export const actions = {
    setParameters: (payload) => ({ type: actionTypes.SET_PARAMETERS, payload }),
};

export function* saga() { }
